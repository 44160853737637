@media screen and (max-width: 640px) {
    .picture_wrapper {
        width: 80vw;
        height: 80vw;
        border-radius: 50%;
        background-image: url("../../public/me.jpg");
        opacity: 100;
        background-repeat: no-repeat;
        background-position-x: -36.8vw;
        background-position-y: -18vw;
        background-size: 150vw;
        margin-top: 3vh;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
}

@media screen and (min-width: 641px) {
    .picture_wrapper {
        width: 20vw;
        height: 20vw;
        border-radius: 50%;
        background-image: url("../../public/me.jpg");
        opacity: 100;
        background-repeat: no-repeat;
        background-position-x: -10.4vw;
        background-position-y: -5vw;
        background-size: 40vw;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
}

@media screen and (min-width: 1025px) {
    .picture_wrapper {
        width: 20vw;
        height: 20vw;
        border-radius: 50%;
        background-image: url("../../public/me.jpg");
        opacity: 100;
        background-repeat: no-repeat;
        background-position-x: -10.4vw;
        background-position-y: -5vw;
        background-size: 40vw;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
}

