@media screen and (max-width: 640px) {
    .nav_wrapper_mobile {
        display: flex;
        align-items: center;
        position: sticky;
        top: 0vh;
        background-color: #18191F;
        padding-top: 2vw;
        padding-bottom: 2vw;
        z-index: 1;
    }

    .nav_logo_container_mobile {
        display: none;
    }

    .nav_buttons_container_mobile {
        display: flex;
        align-items: center;
        margin-top: 1vh;
        gap: 5.3vw;
    }

    .nav_button_container_mobile {
        display: flex;
        align-items: center;
        color: white;
        padding: .2vw 1vw;
        border-radius: 5%;
    }

    .nav_button_container_mobile:hover {
        background-color: rgba(214, 214, 214, 0.137);
    }
}

@media screen and (min-width: 641px) {
    .nav_wrapper {
        display: flex;
        align-items: center;
        position: sticky;
        top: 0vh;
        background-color: transparent;
        padding-top: 1vh;
    }

    .nav_logo_container {
        display: flex;
        align-items: center;
    }

    .nav_buttons_container {
        display: flex;
        align-items: center;
        margin-top: 1vh;
        gap: .5vw;
    }

    .nav_button_container {
        display: flex;
        align-items: center;
        color: white;
        padding: .1vw .4vw;
        border-radius: 5%;
    }

    .nav_button_container:hover {
        background-color: rgba(214, 214, 214, 0.137);
    }

    .nav_button_container:hover {
        cursor: pointer;
    }
}