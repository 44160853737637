@media screen and (min-width: 641px) {
  .down_arrow_container {
    animation: bounce 4.5s infinite;
  }  
}

@media screen and (max-width: 640px) {
  .down_arrow_container {
    animation: bounceMobile 4.5s infinite;
  }  
}


@keyframes bounceMobile {

  0%,
  10%,
  20%,
  30%,
  40%,
  60%,
  80%,
  90%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-2vw);
  }

  70% {
    transform: translateY(-2vw);
  }
}

@keyframes bounce {

  0%,
  10%,
  20%,
  30%,
  40%,
  60%,
  80%,
  90%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-.5vw);
  }

  70% {
    transform: translateY(-.5vw);
  }
}